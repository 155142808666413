<template>

<span>
    <v-layout mb-4>
      <v-flex xs10 ml-2>
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Filiais - Edição</h2>
      </v-flex>
      <v-flex xs2 text-right mr-3>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'branchs'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
    
    <form-branch :branch="branch" :update="true"></form-branch>
</span>
        
</template>

<script>
import FormBranchComponent from "./partial/FormBranchComponent";

export default {
  name: "EditBranchComponent",
  created() {
    this.loadCompanyBranch()
  },
  props: {
    comp_b_id: {
      require: true
    }
  },
  data () {
    return {
      branch: {},
      langs: true
    }
  },
  methods: {
    loadCompanyBranch() {
      this.$store.dispatch('loadCompanyBranch', this.comp_b_id)
        .then(response => this.branch = response)
        .catch(error => {
          this.$swal({
            position: "center",
            icon: "error",
            title: 'Erro',
            text: 'Dados não localizado',
            showConfirmButton: true,
            timer: 6000
          });
        })
    },
  },
  components: {
    formBranch: FormBranchComponent
  }
};
</script>

<style scoped>

</style>
